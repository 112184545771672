






















import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
  },
})
export default class WinLottery extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "还没有活动",
  })
  public title!: string;

  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  public prizeData!: cms.ActivityPrizeDto;

  close(): void {
    this.$emit("close");
  }
  /*
   * 去兑换
   */
  goExchange(): void {
    this.$emit("goExchange");
  }
}
