


















import { Component, Mixins, Prop } from "vue-property-decorator";
import { NavBar, NoticeBar, Image, Swipe, SwipeItem } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [NoticeBar.name]: NoticeBar,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
})
export default class LotteryNotice extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public recordList!: Array<string>; //中奖信息
}
