






import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import LotteryDemo from "@/views/LimitActivities/ActivityDemo/LotteryDemo.vue";
@Component({
  components: {
    LotteryDemo,
  },
})
export default class Lottery extends Mixins(Mixin) {
  mounted(): void {
    let RedEnvelope = this.$refs["lottery-demo"] as LotteryDemo;
    if (RedEnvelope) {
      RedEnvelope.updateData();
    }
  }
}
