






























































































import { Component, Mixins } from "vue-property-decorator";
import { NavBar, Row, Image, Dialog } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import LotteryPrizes from "@/views/LimitActivities/components/Lottery/LotteryPrizes.vue";
import LotteryNotice from "@/views/LimitActivities/components/Lottery/LotteryNotice.vue";
import MyPrize from "@/views/LimitActivities/components/Fission/MyPrize.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    [Dialog.Component.name]: Dialog.Component,
    LotteryPrizes,
    LotteryNotice,
    MyPrize,
  },
})
export default class LotteryDemo extends Mixins(Mixin) {
  showPrivacy = false;
  dialogType = 1;
  recordList: Array<string> = [];
  lotteryInfo: cms.ActivityInfoDto = { menuType: "" };
  prizeList: cms.ActivityWinningRecordDto[] = [];
  integralInfo: cms.LuckDrawAuxiliaryDto = {};
  id = "";
  ratingPrompt = "";
  isoperate = false;
  isShowBack = true;
  updateData(): void {
    this.getAllMethods();
    document.addEventListener("visibilitychange", this.refreshPage);
  }
  refreshPage(): void {
    if (document.visibilityState == "visible") {
      let ua = this.getBrowserEnvironment();
      if (ua.ios) {
        this.getAllMethods();
      }
    }
  }
  beforeDestroy(): void {
    document.removeEventListener("visibilitychange", this.refreshPage);
  }
  //方法集合
  getAllMethods(): void {
    this.isShowBack = this.$route.query.isTab ? false : true; //是否是首页的tab,是不展示返回按钮
    this.id = this.$route.query.id ? String(this.$route.query.id) : "";
    if (this.id) {
      this.getIntegral();
      this.getInfo();
      this.winningRecord();
    }
  }
  //重置
  resetData(): void {
    (this.$refs.lotteryPrizes as LotteryPrizes).resetData();
  }
  //返回
  onClickLeft(): void {
    this.$router.go(-1);
  }

  /*
   * 获取积分
   */
  getIntegral(): void {
    this.ratingPrompt = "";
    this.$api.cmsApi.lucky.findActivityInfoAuxiliaryById(
      this.id,
      ({ data }) => {
        this.integralInfo = data;
      },
      (error) => {
        if (error.includes("@_@")) {
          (this.$refs.lotteryPrizes as LotteryPrizes).title =
            error.split("@_@")[1];
          (this.$refs.lotteryPrizes as LotteryPrizes).isDown = true;
          (this.$refs.lotteryPrizes as LotteryPrizes).isPrize = false;
          (this.$refs.lotteryPrizes as LotteryPrizes).showpPrize = true;
        } else {
          error && this.$toast(error);
        }
      }
    );
  }

  /*
   * 活动信息
   */
  getInfo(): void {
    this.$api.cmsApi.lucky.findActivityInfoById(
      this.id,
      ({ data }) => {
        this.lotteryInfo = data;
        window.document.title = data.activityName || "";
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          if (data.activeShare === 1) {
            this.jsBridge("isShowShare", "1");
          } else {
            this.jsBridge("isShowShare", "0");
          }
          window.startShare = this.shareDate;
        }
      },
      (error) => {
        if (error.includes("@_@")) {
          let code = error.split("@_@")[0];
          if (code == "1128") {
            this.ratingPrompt = error.split("@_@")[1];
          }
        } else {
          error && this.$toast(error);
        }
      }
    );
  }
  //分享
  shareDate(): void {
    let url = `${location.origin}${location.pathname}#/lottery?id=${this.id}`;
    let title = this.lotteryInfo.activityName || "";
    let shareDescribe = this.lotteryInfo.shareDescription || "";
    let shareImg = this.lotteryInfo.shareImageUrl || "";
    this.jsBridge(
      "share",
      `title%&@/${title}%&@,desc%&@/${shareDescribe}%&@,picUrl%&@/${shareImg}%&@,url%&@/${url}`
    );
  }

  /*
   * 活动规则
   */
  activityRules(): void {
    this.showPrivacy = true;
    this.dialogType = 1;
  }

  /*
   * 中奖记录
   */
  winningRecord(): void {
    this.$api.cmsApi.lucky.queryActivityWinningInfo(this.id, ({ data }) => {
      this.recordList = data;
    });
  }
  //关闭九宫格提升弹窗
  operate(operate: boolean): void {
    this.isoperate = operate;
  }

  /*
   * 去我的奖品
   */
  goMyPrize(): void {
    if (this.isoperate) {
      this.$toast("正在抽奖中,请勿进行其他操作");
    } else {
      this.showPrivacy = true;
      this.dialogType = 2;
      this.getMyPrizeInfo();
    }
  }

  /*
   *  奖品列表
   */
  getMyPrizeInfo(): void {
    this.$api.cmsApi.lucky.findMyActivityPrize(this.id, ({ data }) => {
      this.prizeList = data;
    });
  }

  /*
   * 去查看或兑换
   */
  goView(item: cms.ActivityWinningRecordDto): void {
    if (item.prizeStatus === 0) {
      //去兑换
      let data = {
        commodityInfoId: item.trophyId,
        num: item.number || 1,
        winningId: item.id,
        type: item.winningType,
      };
      this.$router.push(`/commodity-exchange?data=${JSON.stringify(data)}`);
    } else {
      //去查看
      this.getViewPrizes(item);
    }
    this.showPrivacy = false;
  }
}
